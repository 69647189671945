<template>
  <v-container fluid>
    <form action="/msgraph/authorize" method="post">
      <button type="submit">Authorize</button>
    </form>
  </v-container>
</template>
<script>
export default {
  name: "Authorize"
}
</script>